export const toCurrency = (x: String | Number | undefined): String => {
    if (typeof x === 'undefined') return '';
    const val = x.toString().replace(/([a-zA-Z]|,)/g, '');
    if (!isNaN(parseInt(val, 10))) {
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
    return val;
};

export const toPounds = (x: String | Number | undefined): String => {
    if (typeof x === 'undefined' || toCurrency(x) === '') return '';
    return `£${toCurrency(x)}`;
};
