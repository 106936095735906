import React from 'react';
import styled from 'styled-components';

const Btn = styled.button`
    width: 100%;
    cursor: pointer;
    outline: none !important;
    overflow: hidden;

    background: white url(images/btn-chevron-right.png) no-repeat;
    background-position: right 0px top;
    color: black;
    border: none;
    height: 48px;
    line-height: 48px;
    text-align: left;
    font-weight: bold;
    font-size: 14px;
 
    margin-bottom: 20px;
    box-shadow: 0px 2px 10px #ccc;
    
    position: relative;
    span { font-size: 14px; padding-left: 44px; padding-right: 30px; text-overflow: ellipsis; word-break: break-all; }
    img { top:5px; left: 5px; position: absolute; }

    @media screen and (max-width: 380px) {
        span { font-size: 12px; }
    }
    @media screen and (max-width: 370px) {
        span { font-size: 10px; }
    }
`;

export const AdvancedTrainingBtn: React.FC<any> = () => {
    const link = 'https://youtu.be/bq6dtml9REk';
    return (
        <Btn onClick={() => (window.open(link))}>
            <img src='images/icon-register.png' alt='' />
            <span>Watch the webinar marketing masterclass</span>
        </Btn>
    )
}

export const DownloadReportBtn: React.FC<any> = () => {
    const link = 'https://www.more2life.co.uk/learning-lab/Market-insight/akg-industry-research-paper-%E2%80%98house-of-the-rising-s'
    return (
        <Btn onClick={() => (window.open(link))}>
            <img src='images/icon-download.png' alt='' />
            <span>Download further market intelligence</span>
        </Btn>
    )
}