import React from 'react';
import styled from 'styled-components';

const Wrap = styled.div`
    h1 { 
        padding-top: 20px;
        text-align: center;
        color: #219445;
        font-weight: normal;
    }
    p { 
        margin: 0px auto;
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 20px;
        max-width: 740px;
        line-height: 1.5em;
        text-align: center; color: #666; 
    }
`;
export const Header: React.FC<any> = () => {
    return (
        <Wrap>
            <h1>Local intelligence</h1>
            <p>
                Our agents have been on a countrywide reconnaissance
                mission, gathering intelligence on equity release activity.
                Click your region on the map below for intelligence on your area:
            </p>
        </Wrap>
    );
  }
  
  