export const Debounced: any = {
    queue: {},
    saveInterval: 500, // 10 seconds
    start: (id: string, callback: Function, ms: number) => {
        if (typeof callback !== 'function') {
            throw new Error('Debounced.start: invalid arguments, at least callback expected');
        }
        const msValue = ms || 500;
        const timeoutId = Debounced.queue[id];
        clearTimeout(timeoutId);
        Debounced.queue[id] = setTimeout(() => {
            callback(); delete Debounced.queue[id];
        }, msValue);
    }
};
