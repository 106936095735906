import React from 'react';
import styled from 'styled-components';
import { the } from './../services/regions';
import { IKV, IAreaRegion } from './../services/types';
import { toPounds } from './../services/formats';
import { AdvancedTrainingBtn, DownloadReportBtn } from './CTA';

const OurIntelligence: React.FC<any> = (props: any) => {
    return props.region.paragraph ? (
        <p>{props.region.paragraph}</p>
    ): (
        <p>
            Our intelligence shows there has been a lot of equity release
            activity in {the(props.region.id)} {props.region.name} in the last year. Report to advanced
            training for help locating targets within your area.
        </p>
    );
}

const LayoutWithImage = styled.div`
    margin-top: 5px;
    display: flex;
    width: 100%;
    
    .l { flex: 1; }
    img { float: right; margin-left: 20px; }
    .hd {
        font-size: 14px;
        font-weight: bold;
        color: #219445;
        padding: 5px 20px;
    }
    p { font-size: 14px; margin-top: 5px; }

    @media (min-width: 832px) {
        .hd { padding: 5px 0px; }
    }
`

const AdvancedTraining: React.FC<any> = () => (
    <LayoutWithImage>
        <div className="l">
            <div className='hd'>Digital lead generation training</div>
            <p>
                Learn from the best in a
                webinar marketing masterclass,
                brought to you by leading operatives
                Phil Calvert and Ges Ray. They take
                you through setting up client
                webinars in a digital world, how to
                deliver a professional webinar, and
                more to help you generate equity
                release leads and spot opportunities
                within your client base.
            </p>
        </div>
        <div className="r">
            <img src='images/cta112.png' alt='' />
        </div>
    </LayoutWithImage>
);

const FiguresWrapper = styled.div`
    .title {
        font-size: 20px;
        color: #219445;
        background: #e8edee;
        padding: 10px 15px;
    }
    table { width: 100%; padding: 20px 15px; background: white; }
    table tbody th { text-align: left; font-size: 14px; }
    table tbody td { text-align: left; font-size: 14px; }
`;

export const Figures: React.FC<any> = (props: IAreaRegion) => {
    const { region } = props;
    if (!region) return null;

    const fp: Array<IKV> = [
        {key: 'Region:', value: region.name},
        {key: 'Total Equity Release Lending:', value: toPounds(region.total)},
        {key: 'Average Housing Wealth:', value: toPounds(region.avgWealth)},
        {key: 'Number of Cases in The Last Year:', value: region.cases},
        {key: 'Typical release:', value: toPounds(region.typical)}
    ];
    return (
        <FiguresWrapper>
            <div className='title'>FIGURES*</div>
            <table cellSpacing={0} cellPadding={3}>
                <tbody>
                    {fp.map(kv => (
                        <tr key={kv.key}><th>{kv.key}</th><td>{kv.value}</td></tr>
                    ))}
                </tbody>
            </table>
            <OurIntelligence region={region}/>
            <AdvancedTraining />
            <AdvancedTrainingBtn />
            <DownloadReportBtn />
            <p><small>* 2022, UK Equity Release Market Monitor, Key Group</small></p>
        </FiguresWrapper>
    )
};
