import { IRegion } from './types'

// Excel helper to generate:
// ="{id: '', name: '" & A5 & "', total: "&B5 &", avgWealth: "&C5 &", cases: "&D5 &", typical: "&E5 &"},"

export const Regions: Array<IRegion> = [
    {
        id: 'east-anglia', name: 'Eastern',
        total: 279989851, avgWealth: 337621, cases: 3083, typical: 90806,
        paragraph: 'The lending demand in the Eastern region remained strong with a total of £279,989,851 released in equity in 2022 '
    },
    {
        id: 'east-midlands', name: 'East Midlands',
        total: 348426509, avgWealth: 281885, cases: 4389, typical: 79385,
        paragraph: 'The lending demand in the East Midlands region remained strong with a total of £348,426,509 released in equity in 2022 '
    },
    {
        id: 'london', name: 'London',
        total: 1015501006, avgWealth: 795308, cases: 4383, typical: 231694,
        paragraph: 'The lending demand in the London region remained strong with a total of £1,015,501,006 released in equity in 2022 '
    },
    {
        id: 'north-east', name: 'North East',
        total: 132465386, avgWealth: 217610, cases: 1900, typical: 69708,
        paragraph: 'The lending demand in the North East region remained strong with a total of £132,465,386 released in equity in 2022 '
    },
    {
        id: 'north-west', name: 'North West',
        total: 418010407, avgWealth: 261936, cases: 5713, typical: 73166,
        paragraph: 'The lending demand in the North West region has increased with a total of £418,010,407 released in equity in 2022'
    },
    {
        id: 'scotland', name: 'Scotland',
        total: 214679035, avgWealth: 237834, cases: 2906, typical: 73884,
        paragraph: 'The lending demand in the Scotland region has increased with a total of £214,679,035 released in equity in 2022'
    },
    {
        id: 'south-east', name: 'South East',
        total: 1552914224, avgWealth: 470171, cases: 12027, typical: 129119,
        paragraph: 'The lending demand in the South East region remained strong with a total of £1,552,914,224 released in equity in 2022'
    },
    {
        id: 'south-west', name: 'South West',
        total: 699887467, avgWealth: 406672, cases: 6148, typical: 113833,
        paragraph: 'The lending demand in the South West region remained strong with a total of £699,887,467 released in equity in 2022'
    },
    {
        id: 'wales', name: 'Wales',
        total: 217833230, avgWealth: 275506, cases: 2758, typical: 78968,
        paragraph: 'The lending demand in the Wales region has increased with a total of £217,833,230 released in equity in 2022'
    },
    {
        id: 'west', name: 'West Midlands',
        total: 394461123, avgWealth: 296978, cases: 4530, typical: 87076,
        paragraph: 'The lending demand in the West Midlands region has increased with a total of £394,461,123 released in equity in 2022'
    },
    {
        id: 'yorkshire', name: 'Yorkshire & Humberside',
        total: 276499175, avgWealth: 247469, cases: 3880, typical: 71258,
        paragraph: 'The lending demand in the Yorkshire and Humberside region has increased with a total of £276,499,175 released in equity in 2022'
    },
];

// prepending region with "the", i.e. "in the North East"
export const the = (regionId: string): string => {
    const noThe = ['scotland', 'yorkshire', 'wales', 'london', 'east-anglia'];
    if (noThe.indexOf(regionId) !== -1 ) return '';
    return 'the';
}
